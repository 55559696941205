import { useTheme } from "@emotion/react";
import { Grid, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import { useReducer, useState } from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";
import CompanyModal from "ui-components/CompanyModal";
import menuIcon from "../../../assets/images/header/menuBar.svg";
import Partner1 from "../../../assets/images/header/Partner1.png";
import Partner2 from "../../../assets/images/header/Partner2.png";
import Partner3 from "../../../assets/images/header/Partner3.png";
import Partner4 from "../../../assets/images/header/Partner4.png";
import Partner5 from "../../../assets/images/header/Partner5.png";
import PPostLogo from "../../../assets/images/logos/PPostLogo.png";

const all_partners = [
  {
    path: Partner1,
    id: 1,
  },
  {
    path: Partner2,
    id: 2,
  },
  {
    path: Partner3,
    id: 3,
  },
  {
    path: Partner4,
    id: 4,
  },
  {
    path: Partner5,
    id: 5,
  },
];

export default function Header() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [buttonName, setButtonName] = useState("");

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      email: "",
      password: "",
      showPassword: false,
      activity: true,
    }
  );

  const theme = useTheme() as any;
  const isLaptop = useMediaQuery(theme.breakpoints.down("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDownLaptop = useMediaQuery(theme.breakpoints.down("lg"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall = useMediaQuery(theme.breakpoints.down("sd"));

  const handleOpenModal = (name: any) => {
    setButtonName(name);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>
          Peoples Post : Votre allié logistique, partout, en toute simplicité !
        </title>
        <link rel="canonical" href="https://ppost.hitech-prime.com/fr" />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://frankme-23.s3.eu-central-1.amazonaws.com/Fav-02.ico"
        ></link>
        <meta
          name="description"
          content="Simplifiez votre Supply Chain en confiant la gestion de vos envois à un expert de la logistique"
        />
      </Helmet>

      <div style={{ width: "100%" }}>
        <div
          className="webscreen"
          style={{ position: "absolute", top: "0px", width: "100%" }}
        >
          {!isMobile && !isDownLaptop && (
            <div className="hero_banne">
              <Grid
                container
                style={{
                  height: "10%",
                  paddingTop: isLaptop ? 20 : 40,
                  width: "100%",
                  paddingLeft: isLaptop ? 70 : "70px",
                }}
              >
                <Grid item xs={12} md={2} lg={2}></Grid>
                <Grid item xs={12} md={2} lg={1.8}>
                  <div>
                    <img
                      onClick={() => {
                        navigate("/fr");
                      }}
                      src={PPostLogo}
                      style={{
                        objectFit: "contain",
                        width: isLaptop ? 120 : 150,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Grid>
                {/* Header Buttons */}
                <Grid item xs={12} md={2} lg={3.8}>
                  <div style={{ display: "flex" }}>
                    <div
                      className="header_btn_fill_1920"
                      style={{
                        width: isLaptop ? 90 : 108,
                        height: isLaptop ? 30 : 38,
                        fontSize: isLaptop ? 12 : 14,
                      }}
                      onClick={() => {
                        navigate("/fr");
                      }}
                    >
                      Accueil
                    </div>

                    <div
                      className="header_btn_outline_1920"
                      style={{
                        width: isLaptop ? 110 : 163,
                        height: isLaptop ? 30 : 38,
                        fontSize: isLaptop ? 12 : 14,
                      }}
                      onClick={() => handleOpenModal(`Nous contacter`)}
                    >
                      Nous contacter
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={3} lg={2}></Grid>
              </Grid>
            </div>
          )}
          {isDownLaptop && !isMobile && (
            <div className="hero_banne">
              <Grid
                container
                style={{
                  height: "10%",
                  paddingTop: isLaptop ? 20 : 40,
                  width: "100%",
                }}
              >
                <Grid item xs={12} sm={1.5} md={1.5} lg={1.5}></Grid>
                <Grid item xs={12} sm={9} md={9} lg={9}>
                  <div style={{display:"flex", width:"100%", alignItems:"center", justifyContent:"space-between"}}>
                    <div>
                      <img
                        onClick={() => {
                          navigate("/fr");
                        }}
                        src={PPostLogo}
                        style={{
                          objectFit: "contain",
                          width: isLaptop ? 120 : 150,
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        className="header_btn_fill_1920"
                        style={{
                          width: isLaptop ? 90 : 108,
                          height: isLaptop ? 30 : 38,
                          fontSize: isLaptop ? 12 : 14,
                        }}
                        onClick={() => {
                          navigate("/fr");
                        }}
                      >
                        Accueil
                      </div>

                      <div
                        className="header_btn_outline_1920"
                        style={{
                          width: isLaptop ? 110 : 163,
                          height: isLaptop ? 30 : 38,
                          fontSize: isLaptop ? 12 : 14,
                        }}
                        onClick={() => handleOpenModal(`Nous contacter`)}
                      >
                        Nous contacter
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={1.5} md={1.5} lg={1.5}></Grid>
              </Grid>
            </div>
          )}
          {isMobile && (
            <div
              style={{
                paddingInline: "40px",
                paddingTop: "30px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  onClick={() => {
                    navigate("/fr");
                  }}
                  src={PPostLogo}
                  style={{
                    objectFit: "contain",
                    width: 100,
                  }}
                />
                <img
                  src={menuIcon}
                  style={{
                    objectFit: "contain",
                    width: 40,
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <CompanyModal
          activity={modalOpen}
          handleCloseModal={handleCloseModal}
          buttonName={buttonName}
        />
      </div>
    </>
  );
}

Header.defaultProps = {
  classes: {},
};

Header.propTypes = {
  classes: PropTypes.object,
};
