import FooterBg1024 from "../../../assets/images/footer/1024Footer.svg";
import FooterBg1440 from "../../../assets/images/footer/Footer_1440.png";
import FooterBackground from "../../../assets/images/header/Group18.png";

import FooterBg480 from "../../../assets/images/footer/FooterBg480.svg";

import { useTheme } from "@emotion/react";
import { Grid, useMediaQuery } from "@mui/material";
import classNames from "classnames";
import { useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiServices from "services/RequestHandler";
import InputField from "ui-components/TextFeild";
import blueLogo from "../../../assets/images/header/FootLogo.png";
import orangeDot from "../../../assets/images/header/orangeDot.png";
import Divider from "../../../assets/images/header/Vector6.png";

export default function Footer(props: any) {
  const { classes } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme() as any;
  const isLaptopUp = useMediaQuery(theme.breakpoints.down("xxl"));
  const isLaptop = useMediaQuery(theme.breakpoints.down("xl"));
  const isLarge = useMediaQuery(theme.breakpoints.down("lg"));
  const is720 = useMediaQuery(theme.breakpoints.down("sd"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      first_name: "",
      business: "",
      email: "",
      errorPanels: {
        first_name: false,
        business: false,
        email: false,
      },
    }
  );

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleKeyPress = (e: any) => {
    const value = e.target.value;
    if (!emailRegex.test(value)) {
      setState({
        ...state,
        errorPanels: { ...state.errorPanels, email: true },
      });
    } else {
      setState({
        ...state,
        errorPanels: { ...state.errorPanels, email: false },
      });
    }
  };

  const handleSend = () => {
    try {
      if (!state?.first_name || !state?.business || !state?.email) {
        setState({
          errorPanels: {
            [!state?.first_name ? "first_name" : "test1"]: true,
            [!state?.business ? "business" : "test1"]: true,
            [!state?.email ? "email" : "test1"]: true,
          },
        });
      } else {
        const data = {
          first_name: state?.first_name || "",
          business: state?.business || "",
          email: state?.email || "",
          subject: "Demander d’informations PeoplePost",
        };
        const response = apiServices.postFromApi(
          "helper/ppost/send-query",
          data,
          ""
        );
        toast.success("L'e-mail a été envoyé avec succès!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    if (location.pathname === "/fr/terms-and-conditions") {
      navigate("/fr");
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <div
      style={{
        backgroundImage: isMobile
          ? `url(${FooterBg480})`
          : isLarge
          ? `url(${FooterBg1024})`
          : isLaptop
          ? `url(${FooterBg1440})`
          : `url(${FooterBackground})`,
        width: "100%",
        height: isLaptopUp ? 600 : isLaptop ? 500 : "700px",
        backgroundSize: "cover",
        marginTop: 90,
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid container spacing={isMobile ? 0 : 2}>
        <Grid item xs={1} sm={is720 ? 2 : 1.5} md={1.5} lg={2.3}></Grid>

        <Grid
          item
          xs={10}
          sm={is720 ? 8 : 9}
          md={9}
          lg={7.4}
          style={{
            padding: isMobile ? "25px" : "80px 0px",
            paddingInline: isSmall ? "20px" : "",
          }}
        >
          <div>
            <span
              style={{
                color: "#3026F1",
                fontSize: isSmall ? 18 : isMobile ? 22 : 30,
                fontWeight: 400,
                fontFamily: "Inter-Regular",
                whiteSpace: isMobile ? "wrap" : "nowrap",
              }}
            >
              Demander plus d’informations
            </span>
          </div>

          <Grid
            container
            spacing={isMobile ? 0 : 2}
            style={{
              marginTop: isMobile ? 0 : 30,
              marginLeft: 0,
              paddingLeft: 0,
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              sm={6}
              style={{ paddingLeft: 0, width: "100%", marginTop: "15px" }}
            >
              <InputField
                fullWidth
                value={state?.first_name}
                size={"small"}
                variant={"outlined"}
                placeholder={"Nom/Prénom"}
                InputProps={{
                  className: classNames(classes?.input_feild, "Inter-Regular"),
                }}
                inputProps={{
                  className: classNames(classes?.input_feild, "Inter-Regular"),
                }}
                bgColor={"#ffffff"}
                error={!state?.first_name && state?.errorPanels?.first_name}
                classes={classes}
                onChange={(e: any) => {
                  const { value } = e.target;
                  setState({
                    first_name: value,
                  });
                }}
                style={{ width: "100%" }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              sm={6}
              style={{ paddingLeft: 0, width: "100%", marginTop: "15px" }}
            >
              <InputField
                fullWidth
                value={state?.business}
                size={"small"}
                variant={"outlined"}
                placeholder={"Entreprise"}
                InputProps={{
                  className: classNames(classes?.input_feild, "Inter-Regular"),
                }}
                inputProps={{
                  className: classNames(classes?.input_feild, "Inter-Regular"),
                }}
                bgColor={"#ffffff"}
                error={!state?.business && state?.errorPanels?.business}
                classes={classes}
                onChange={(e: any) => {
                  const { value } = e.target;
                  setState({
                    business: value,
                  });
                }}
                style={{
                  width: "100%",
                  marginLeft: isMobile ? 0 : "10px",
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={4} sm={6} style={{ marginTop: 15 }}>
            <InputField
              fullWidth
              value={state?.email}
              size={"small"}
              variant={"outlined"}
              placeholder={"Mail"}
              InputProps={{
                className: classNames(classes?.input_feild, "Inter-Regular"),
              }}
              inputProps={{
                className: classNames(classes?.input_feild, "Inter-Regular"),
              }}
              bgColor={"#ffffff"}
              error={!state?.email && state?.errorPanels?.email}
              classes={classes}
              onChange={(e: any) => {
                const { value } = e.target;
                setState({
                  email: value,
                });
              }}
              style={{ width: "100%" }}
              onKeyPress={(e: any) => {
                const allowedChars = /^[a-zA-Z0-9@._-]+$/;
                if (!allowedChars.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Grid>

          <Grid item xs={12} md={3} lg={2} sm={3} style={{ marginTop: 15 }}>
            <div
              style={{
                width: "100%",
                height: 38,
                borderRadius: "20px",
                backgroundColor: "#3026F1",
                color: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "13px",
                marginRight: 15,
                cursor: "pointer",
                fontFamily: "Inter-Regular",
              }}
              onClick={handleSend}
            >
              Envoyer
            </div>
          </Grid>

          {/* Divider */}
          <Grid item xs={12} md={12} style={{ paddingTop: 60 }}>
            <img src={Divider} alt="divider" />
          </Grid>
          {/* div */}
          <div
            style={{
              display: "flex",
              paddingTop: 20,
              gap: isLaptopUp ? 25 : "35px",
              // justifyContent: "space-between",
            }}
          >
            {/* <div> */}
            <img
              src={blueLogo}
              alt="blueLogo"
              style={{
                objectFit: "contain",
                width: isMobile ? 100 : isLaptopUp ? 100 : 165,
                // height: 110,
              }}
            />
            {/* </div> */}
            <div
              style={{
                display: "flex",
                rowGap: isLaptop ? 0 : 0,
                flexWrap: isLarge ? "wrap" : "nowrap",
                fontSize: isMobile ? "12px" : isLarge ? 10 : isLaptop ? 12 : 16,
                alignItems: "center",
              }}
            >
              <div
                style={{ marginTop: 15, marginRight: isLaptopUp ? 25 : "35px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={orangeDot}
                    alt="orangeDot"
                    style={{
                      height: 7,
                      width: 7,
                      objectFit: "contain",
                      marginRight: 8,
                    }}
                  />
                  <span
                    style={{
                      fontFamily: "Inter-Regular",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                    onClick={handleClick}
                  >
                    Accueil
                  </span>
                </div>
              </div>

              <div
                style={{ marginTop: 15, marginRight: isLaptopUp ? 25 : "35px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={orangeDot}
                    alt="orangeDot"
                    style={{
                      height: 7,
                      width: 7,
                      objectFit: "contain",
                      marginRight: 8,
                    }}
                  />

                  <span
                    style={{
                      fontFamily: "Inter-Regular",
                      whiteSpace: is720 ? "wrap" : "nowrap",
                    }}
                  >
                    {" "}
                    Nous contacter :{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <a
                        href="mailto:hello@peoplespost.fr"
                        style={{
                          cursor: "pointer",
                          color: "inherit",
                          textDecoration: "none",
                          whiteSpace: "nowrap",
                        }}
                      >
                        hello@peoplespost.fr
                      </a>
                    </span>
                  </span>
                </div>
              </div>

              <div
                style={{ marginTop: 15, marginRight: isLaptopUp ? 25 : "35px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    navigate("/terms-and-conditions");
                  }}
                >
                  <img
                    alt="orangeDot"
                    src={orangeDot}
                    style={{
                      height: 7,
                      width: 7,
                      objectFit: "contain",
                      marginRight: 8,
                    }}
                  />
                  <span
                    style={{
                      fontFamily: "Inter-Regular",
                      cursor: "pointer",
                      whiteSpace: isMobile ? "wrap" : "nowrap",
                    }}
                  >
                    Termes et conditions / Politique de confidentialité
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={1} sm={is720 ? 2 : 1.5} md={1.5} lg={2.3}></Grid>
      </Grid>
    </div>
  );
}
