/*
  Created By Muhammad Hassaan
  Date 15 April 2022
*/
import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { InputAdornment, TextField } from "@mui/material";

export default function InputField(props: any) {
  const {
    input_label,
    required,
    value,
    classes,
    error,
    rows,
    multiline,
    disabled,
    input_label_2,
    InputProps,
    startAdornment,
    bgColor,
    onKeyPress,
  } = props;
  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      style={{
        width: "100%",
        // display: "flex", alignItems: "center"
      }}
    >
      {input_label_2 ? (
        <div style={{ display: "flex" }}>
          <label
            className={classNames(
              classes.input_label_style,
              "RobotoCondensed-Regular",
              error && classes.color_red
            )}
            style={{ flex: 1 }}
          >
            {input_label}
            {required && !value ? <span style={{ color: "red" }}> *</span> : ""}
          </label>
          <span style={{ paddingRight: 15 }}>{input_label_2}</span>
        </div>
      ) : (
        <label
          className={classNames(
            classes.input_label_style,
            "RobotoCondensed-Regular",
            error && classes.color_red
          )}
        >
          {input_label}
          {required && !value ? <span style={{ color: "red" }}> *</span> : ""}
        </label>
      )}

      <TextField
        {...props}
        rows={rows}
        multiline={multiline}
        disabled={disabled}
        onKeyPress={onKeyPress}
        sx={{
          "& .MuiInputBase-input::placeholder": {
            color: "black !important",
            fontFamily: "Inter-Regular",
          },
        }}
        // styles={{
        //   input: {
        //     "&:hover": {
        //       color: "blue",
        //       fontFamily: "Inter-Regular",
        //     },
        //     fontFamily: "Inter-Regular",
        //     fontSize: 16,
        //     paddingLeft: 5,
        //     fontWeight: "normal",
        //     lineHeight: "normal",
        //     letterSpacing: "normal",
        //     color: "black",
        //   },
        //   root: {
        //     borderRadius: 4,
        //     background: "#F9D9B6",
        //     backgroundColor: "#F9D9B6",
        //     fontFamily: "Inter-Regular",
        //     fontSize: 16,
        //     fontWeight: "normal",
        //     lineHeight: "normal",
        //     letterSpacing: "normal",
        //     color: "black",
        //     // border: "1px solid #FF0000 !important",
        //     border: error
        //       ? "1px solid #FF0000 !important"
        //       : "1px solid #0000003b",
        //     "&:after": {
        //       // borderColor: error ? "red" : "#0000003b",
        //       border: "none",
        //     },
        //     "&:hover": {
        //       borderColor: "green",
        //       "&:after": {
        //         // borderColor: error ? "red" : "#0000003b",
        //         border: "none",
        //       },
        //     },
        //   },
        //   rootFocused: {
        //     "&:after": {
        //       border: "1px solid yellow",
        //     },
        //     "&:hover": {
        //       borderColor: "pink",
        //       "&:after": {
        //         border: "none",
        //       },
        //     },
        //   },
        //   rootHovered: {
        //     "&:after": {
        //       border: "1px solid transparent",
        //     },
        //     "&:hover": {
        //       borderColor: "purple",
        //       "&:after": {
        //         // borderColor: error ? "red" : "#0000003b",
        //         border: "none",
        //       },
        //     },
        //   },
        //   rootDisabled: {
        //     fontFamily: "Inter-Regular",
        //     fontSize: 16,
        //     fontWeight: "normal",
        //     lineHeight: "normal",
        //     letterSpacing: "normal",
        //     color: "black",
        //     borderRadius: 4,
        //     background: "#F9D9B6",
        //     backgroundColor: "#F9D9B6",
        //     "&:after": {
        //       borderColor: "#0000003b",
        //       borderRadius: 4,
        //     },
        //   },
        // }}
        InputProps={{
          ...InputProps,
          style: {
            color: "#000000",
            fontFamily: "Inter-Regular",
            paddingLeft: 0,
            backgroundColor: bgColor,
          },
          startAdornment: (
            <InputAdornment
              position="start"
              style={{
                display: "flex",
                placeContent: "center",
                placeItems: "center",
                marginLeft: "10px",
                background: "transparent",
              }}
            >
              {startAdornment}
            </InputAdornment>
          ),
        }}
        inputProps={{
          style: {
            color: "#000000",
            fontFamily: "Inter-Regular",
            paddingLeft: 1,
            backgroundColor: bgColor,
          },
        }}
      />
    </form>
  );
}
InputField.defaultProps = {
  input_label: "",
  value: "",
  required: false,
  fullWidth: true,
  error: false,
  classes: {},
  size: "small",
  variant: "outlined",
  type: "text",
  placeholder: "",
  InputProps: null,
  inputProps: null,
  InputLabelProps: null,
  style: null,
  onChange: null,
  rows: 1,
  multiline: false,
  disabled: false,
  input_label_2: "",
  bgColor: "",
  onKeyPress: null,
};

InputField.propTypes = {
  input_label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "standard"]),
  required: PropTypes.bool,
  classes: PropTypes.object,
  InputProps: PropTypes.any,
  inputProps: PropTypes.any,
  InputLabelProps: PropTypes.any,
  style: PropTypes.any,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  bgColor: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.any,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  input_label_2: PropTypes.any,
  onKeyPress: PropTypes.func,
};
