import React from "react";
import PropTypes from "prop-types";
import { customFlagList } from "../../utils/flagsList";
import default_icon from "../../assets/images/country-flags/France.png";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import "./InputFlag.css";
import classNames from "classnames";
import { useTheme } from "@emotion/react";

function FlagInputField(props: any) {
  const { label, error, isDisabled, disabled, name, value, classes, required } =
    props;

  const theme = useTheme() as any;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      openList: false,
      open: false,
      countryList: customFlagList,
      search: "",
      label: "",
      Divider: true,
      countryIcon: default_icon,
      countryCode: "+33",
      isFlagEnable: false,
      countryName: "",
    }
  );

  React.useEffect(() => {
    get_country_by_country_code(props.countryCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.countryCode]);

  const get_country_by_country_code = (code: any) => {
    try {
      const selectedCountry = state.countryList?.filter(
        (item: any) => item.dial_code === code
      );

      setState({
        countryIcon: selectedCountry[0].flag,
        countryCode: selectedCountry[0].dial_code,
        countryName: selectedCountry[0].name,
      });
    } catch (error) {}
  };

  const handle_open_list = () => {
    setState({
      open: !state.open,
      countryList: customFlagList,
    });
  };

  const filter_countries = (e: any) => {
    const filter = customFlagList?.filter(
      (phone) =>
        phone.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 ||
        phone.dial_code.indexOf(e.target.value) !== -1 ||
        phone.code.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    );
    setState({
      countryList: filter,
    });
  };

  const on_click_item = (country: any) => {
    try {
      setState({
        countryIcon: country.flag,
        countryCode: country.dial_code,
        countryList: customFlagList,
        countryName: country.name,
      });
      handle_open_list();
      props.handleChangeMobileCode(
        country.dial_code as any,
        props.codeName as any
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <label
        className={classNames(
          classes.input_label_style,
          "Open Sans",
          error && classes.color_red
        )}
      >
        {label}{" "}
        {required && <span style={{ color: "red", marginLeft: "3px" }}>*</span>}
      </label>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: 16,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 4,
            paddingInline: isMobile ? 5 : 15,
            minWidth: isMobile ? 60 : 125,
            borderRadius: 35,
            background: "#F9D9B6",
            border: "1px solid #bfa78c",
          }}
          className={`mobile-container ${"mobile-input-background"} ${
            isDisabled ? "mobile-input-background" : ""
          }`}
        >
          <div
            style={{ fontFamily: "Inter-Regular" }}
            className={disabled ? "" : "cursor-pointer-caret"}
            onClick={() => {
              if (isDisabled) {
                return;
              } else {
                handle_open_list();
              }
            }}
          >
            <img
              alt=""
              title="Click Icon to see Country List"
              className="mobile-container-caret"
              src={state?.countryIcon?.default || state?.countryIcon}
              style={{
                height: 35,
                objectFit: "contain",
                filter: "drop-shadow(rgb(0,0,0) 0 1px 2px)",
              }}
            />
          </div>
          <div>
            <span
              className="mobile-container-countrycode"
              style={{
                color: "#181818",
                fontSize: 16,
                lineHeight: "20px",
                letterSpacing: "normal",
                fontFamily: "Inter-Regular",
              }}
            >
              {state?.countryCode}
            </span>
          </div>
        </div>

        <div
          className={`mobile-container ${
            error ? "mobile-input-background-red" : "mobile-input-background"
          } ${isDisabled ? "mobile-input-background" : ""}`}
          style={{
            flex: 1,
            background: "#F9D9B6",
            borderRadius: "35px",
            border: error ? "1px solid red" : "1px solid #bfa78c",
          }}
        >
          <div
            className="mobile-container-input-container"
            style={{ display: "flex" }}
          >
            <form
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                autoComplete="off"
                maxLength={15}
                type="tel"
                className="mobile-container-input"
                style={{
                  fontFamily: "Inter-Regular",
                  fontSize: 16,
                  lineHeight: "20px",
                  letterSpacing: "normal",
                  color: "#181818",
                  background: "#F9D9B6",
                  borderRadius: 35,
                  paddingLeft: 11,
                }}
                value={value}
                name={name}
                disabled={isDisabled ? true : false}
                onChange={(event: any) => {
                  props.handleChangeMobile(event);
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={(event) => {
                  const clipboardData = event.clipboardData || window.Clipboard;
                  const pastedText = clipboardData.getData("text");
                  if (!/^\d+$/.test(pastedText)) {
                    event.preventDefault();
                  }
                }}
              />
            </form>
          </div>
        </div>
      </div>

      <Dialog maxWidth="xs" open={state?.open} onClose={handle_open_list}>
        <DialogTitle
          style={{
            paddingBottom: "10px",
            background: "#3026F1",
          }}
        >
          <div
            style={{
              flex: 1,
              fontSize: 23,
              fontFamily: "Inter-Regular",
              color: "white",
              textTransform: "capitalize",
            }}
            className={`${classes?.modal_title || ""}`}
          >
            <p style={{ textAlign: "center" }}>Select Country Dialing Code</p>
          </div>
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              padding: "15px 0px 0px 0px",
            }}
          >
            <TextField
              fullWidth
              size="medium"
              autoFocus
              InputLabelProps={{ shrink: true }}
              variant="standard"
              placeholder="Search..."
              onChange={(e) => filter_countries(e)}
              InputProps={{
                style: { fontSize: 16 },

                startAdornment: (
                  <InputAdornment position="start">
                    <Search className={classes?.color_c} />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 15,
                fontSize: 16,
                className: classes?.search_input,
              }}
            />
          </div>
        </DialogContent>

        <DialogContent className="mobile-list-width">
          <div className="mobile-list">
            {state?.countryList?.map((_country: any, index: any) => {
              return (
                <div key={`${_country?.title}-${index}`}>
                  {_country?.title ? (
                    <div
                      className="country-title"
                      style={{
                        background: "#F9D9B6",
                        padding: "3px",
                        paddingLeft: "22px",
                        textTransform: "capitalize",
                        color: "black",
                        fontFamily: "Inter-Regular",
                        fontSize: "20px",
                      }}
                    >
                      {_country?.name}
                    </div>
                  ) : (
                    <div
                      className="flag-item"
                      onClick={() => on_click_item(_country)}
                    >
                      <Grid
                        container
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid xs={1} item>
                          <img
                            className="mobile-flag-icon"
                            src={_country.flag?.default || _country.flag}
                            title={_country?.name}
                            alt="Flag Icon"
                          />
                        </Grid>
                        <Grid
                          xs={8}
                          item
                          style={{
                            paddingLeft: 10,
                            color: "black",
                            fontFamily: "Inter-Regular",
                            fontSize: "18px",
                            textTransform: "capitalize",
                          }}
                          className={`${classes?.text_1}`}
                        >
                          {_country?.name}
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            color: "black",
                            fontFamily: "Inter-Regular",
                            textTransform: "uppercase",
                            fontSize: "18px",
                          }}
                          className={`${classes?.text_1}`}
                        >
                          {_country?.dial_code}
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

FlagInputField.defaultProps = {
  label: " ",
  required: false,
  classes: {},
  name: "",
  codeName: "",
  value: "",
  countryCode: "",
  handleChangeMobileCode: {},
  handleChangeMobile: {},
  enableFlagInput: "",
  iso: "",
  isDisabled: false,
  error: false,
};

FlagInputField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  classes: PropTypes.any,
  name: PropTypes.any,
  codeName: PropTypes.any,
  value: PropTypes.any,
  countryCode: PropTypes.any,
  handleChangeMobileCode: PropTypes.func,
  handleChangeMobile: PropTypes.func,
  enableFlagInput: PropTypes.func,
  iso: PropTypes.any,
  isDisabled: PropTypes.bool,
  error: PropTypes.bool,
};

export default FlagInputField;
